import React from 'react';
import { default as styled, css } from 'styled-components';
import classnames from 'classnames';

import { IconPijlRechts } from '@dop/ui-icons/svg/pijlRechts';
import { Link } from 'moduleAlias/project/src/components/link/link';
import { defaultTransition } from 'moduleAlias/project/src/styles/includes/transition';

export const createHoverable = (hoverStyle) => (Component) => (Parent) =>
	styled(Component)`
		${Parent}:hover &,
		${Parent}:focus &,
		${Parent}:active & {
			${hoverStyle};
		}
	`;

export const createUnderliner = createHoverable(css`
	text-decoration: none;
	border-bottom: 2px solid;
`)(styled.span`
	${defaultTransition};
	text-decoration: none;
	border-bottom: 2px solid transparent;
`);

/**
 * @deprecated - use Alink from design-system instead, or createUnderline/BlockLink.underliner for legacy code
 */
export const Underliner = ({ children }) => (
	<span className="underliner">{children}</span>
);

export const MoreLink = ({ children, to, className = '' }) => (
	<Link className={classnames('more link', className)} to={to}>
		<Underliner>{children}</Underliner>
		<IconPijlRechts className="more-icon goIcon linkArrow" />
	</Link>
);
